// This setup jquery to automatically send the DJANGO CSRF cookies
// each time we are  doing an ajax call on our own domain (except for GET, HEAD, etc..  who don't need them)
// for more informations see https://docs.djangoproject.com/en/1.7/ref/contrib/csrf/

jQuery(document).ajaxSend((event, xhr, settings) => {
    function getCookie(name) {
        let cookieValue = null;
        if (document.cookie && document.cookie != '') {
            const cookies = document.cookie.split(';');
            for (let i = 0; i < cookies.length; i++) {
                const cookie = jQuery.trim(cookies[i]);
                // Does this cookie string begin with the name we want?
                if (cookie.substring(0, name.length + 1) == (`${name}=`)) {
                    cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
                    break;
                }
            }
        }
        return cookieValue;
    }
    function sameOrigin(url) {
        // url could be relative or scheme relative or absolute
        const host = document.location.host; // host + port
        const protocol = document.location.protocol;
        const sr_origin = `//${host}`;
        const origin = protocol + sr_origin;
        // Allow absolute or scheme relative URLs to same origin
        return (url == origin || url.slice(0, origin.length + 1) == `${origin}/`) ||
            (url == sr_origin || url.slice(0, sr_origin.length + 1) == `${sr_origin}/`) ||
            // or any other URL that isn't scheme relative or absolute i.e relative.
            !(/^(\/\/|http:|https:).*/.test(url));
    }
    function safeMethod(method) {
        return (/^(GET|HEAD|OPTIONS|TRACE)$/.test(method));
    }

    if (!safeMethod(settings.type) && sameOrigin(settings.url)) {
        const cookie_name = window.railfleet_settings.CSRF_COOKIE_NAME;
        xhr.setRequestHeader('X-CSRFToken', getCookie(cookie_name));
    }
});
