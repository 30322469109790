import 'whatwg-fetch';
import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.css';
import i18n from 'i18next';

import * as Sentry from '@sentry/browser';

// for ie11, code is taken from https://developer.mozilla.org/en-US/docs/Web/API/Element/classList
import './classlist_polyfill';

import '../js/ajax_django_magic';
import '../css/skeleton.css';
import '../css/ios_react_fix.css';

import en_language from '../i18n/en/translation.json';
import fr_language from '../i18n/fr/translation.json';
import de_language from '../i18n/de/translation.json';
import nl_language from '../i18n/nl/translation.json';
import it_language from '../i18n/it/translation.json';
import sv_language from '../i18n/sv/translation.json';

import { possible_languages } from '../../../railfleet/lib/compounds/pages/authentication/baseForm/LanguageSelector';
import '../glyphicons/css/glyphicons.css';
import '../glyphicons/css/glyphicons-filetypes.css';
import '../glyphicons/css/glyphicons-halflings.css';
import '../glyphicons/css/glyphicons-social.css';

require("regenerator-runtime/runtime");
var navigatorLanguage = navigator.language
    || navigator.userLanguage;
i18n.init({
    lng: window.settings.django_language,
    resources: {
        en: { translation: en_language },
        fr: { translation: fr_language },
        de: { translation: de_language },
        nl: { translation: nl_language },
        it: { translation: it_language },
        sv: { translation: sv_language },
    },
    // allow keys to be phrases having `:`, `.`
    nsSeparator: false,
    keySeparator: false,

    fallbackLng: possible_languages.map(v => v.toLowerCase()).includes(navigatorLanguage) ? navigatorLanguage : 'en',
    // do NOT consider empty string as translated values
    returnEmptyString: false,
});

if (window.settings.django_language) {
    Cookies.set('django_language', window.settings.django_language, { path: '/' });
}

const subdomain = window.location.host.split('.')[0];
const environment = window.settings.SENTRY_ENV;
const ignoreErrors = [
    'ResizeObserver loop limit exceeded',
    'ResizeObserver loop completed with undelivered notifications.',
    'Non-Error promise rejection captured',
]

Sentry.init({ ignoreErrors: ignoreErrors, dsn: window.settings.RAVEN_DSN, release: window.settings.release, environment: environment });
Sentry.setUser(window.user_info);
Sentry.setTag("subdomain", subdomain);

const current_hash = __webpack_hash__; //eslint-disable-line

function ClearLocalStorage() {
    // localStorage.clear();

    // clear local storage except for preference settings
    const arr = [];
    // Iterate over localStorage and insert the keys to delete
    for (let i = 0; i < localStorage.length; i += 1) {
        if (localStorage.key(i).substring(0, 5) !== 'PREF_') {
            arr.push(localStorage.key(i));
        }
    }

    // Iterate over arr and remove the items by key
    for (let i = 0; i < arr.length; i += 1) {
        localStorage.removeItem(arr[i]);
    }
}

try {
    let user = localStorage.getItem('logged_user');
    if (user === 'undefined') user = undefined;
    const current_version = localStorage.getItem('js_build_version');
    if (window.settings.user !== user || (current_version !== current_hash && process.env.NODE_ENV === 'production')) {
        console.log('New version detected: Clear localstorage');
        ClearLocalStorage();
        localStorage.setItem('logged_user', window.settings.user);
        localStorage.setItem('js_build_version', current_hash);
    }
} catch (err) {
    ClearLocalStorage();
    localStorage.setItem('logged_user', window.settings.user);
    localStorage.setItem('js_build_version', current_hash);
}
